<template>
  <main-card :id="'rule' + createdRule.id" class="rule__card">
    <!--    <base-loader v-if="loading || created"></base-loader>-->
    <base-loader v-if="loading"></base-loader>
    <div v-else>
      <!--      <page-block :title="$t('rule.title')" top="null" size="bigger">-->
      <page-block title=" " top="null" size="bigger">
        <plain-button
          icon="delete"
          color="del"
          class="base-basket-item__actions-item"
          @click="deleteRule(createdRule.id)"
        />
        <div class="l-col">
          <base-input
            v-model="createdRule.name"
            type="text"
            :name="$t('rule.name')"
            :required="true"
            placeholder="Имя правила"
            use-reactive-validation
            :label="$t('rule.name')"
            class="form__field--input"
          />
          <!--            :hint="$t('rule.hint')"-->
        </div>
        <div class="l-col">
          <base-input
            v-model="createdRule.description"
            type="text"
            :name="$t('rule.description')"
            placeholder="Описание правила"
            :label="$t('rule.description')"
            class="form__field--input"
          />
          <!--            :hint="$t('rule.deschint')"-->
        </div>
        <div class="rule__form l-col">
          <div class="rule__field">
            <base-select
              v-model="createdRule.balancer.protocol"
              select-label=""
              :hide-selected="true"
              :preselect-first="true"
              :required="true"
              :searchable="false"
              :field-label="$t('protocol.balancer')"
              :options="protocolRules"
              size="medium"
              :allow-empty="false"
              class="new-disk__services"
              @input="onChangeRule(createdRule.id, 'balancerProtocol', $event)"
            >
            </base-select>
          </div>
          <div class="rule__field">
            <base-input
              v-model="createdRule.balancer.port"
              type="number"
              :required="true"
              use-reactive-validation
              use-before-validation
              :custom-error-messages="
                customValidationMsgs
                  ? customValidationMsgs
                  : validateNum(createdRule.balancer.port, 1, 65535)
                  ? createdRule.balancer.port
                  : null
              "
              :pattern="
                hasBalancerEnabledPort
                  ? 'sdsd'
                  : validateNum(createdRule.balancer.port, 1, 65535)
                  ? createdRule.balancer.port
                  : 'false'
              "
              :label="$t('port.balancer')"
              class="form__field--input"
              @input="onChangeRule(createdRule.id, 'balancerPort', $event)"
            >
            </base-input>
          </div>
          <div class="rule__field">
            <base-select
              v-model="createdRule.server.protocol"
              select-label=""
              :hide-selected="true"
              :preselect-first="true"
              :required="true"
              :field-label="$t('protocol.server')"
              :searchable="false"
              :options="createdRule.protocolServers"
              :disabled="createdRule.balancer.protocol !== 'TCP'"
              size="medium"
              :allow-empty="false"
              class="new-disk__services"
              @input="onChangeRule(createdRule.id, 'serverProtocol', $event)"
            >
            </base-select>
          </div>
          <div class="rule__field">
            <base-input
              v-model="createdRule.server.port"
              type="number"
              use-reactive-validation
              use-before-validation
              :required="true"
              :custom-error-messages="
                customValidationServerMsgs
                  ? customValidationServerMsgs
                  : validateNum(createdRule.server.port, 1, 65535)
                  ? createdRule.server.port
                  : null
              "
              :pattern="
                validateNum(createdRule.server.port, 1, 65535) ? createdRule.server.port : 'false'
              "
              :label="$t('port.server')"
              class="form__field--input"
            >
              <template #fieldAfter> </template>
            </base-input>
          </div>
          <div v-if="createdRule.isSsl" class="rule__field">
            <base-select
              v-model="createdRule.ssl"
              :hide-selected="true"
              :searchable="false"
              select-label=""
              :custom-label="sslNames"
              :options="ssl"
              track-by="id"
              placeholder="Выберите SSL-сертификат"
              field-label="SSL Сертификат"
              size="medium"
              :allow-empty="false"
              @input="onChangeRule(createdRule.id, 'ssl', $event)"
            >
            </base-select>
          </div>
        </div>
      </page-block>
      <page-block title="" size="bigger">
        <!--        <div class="l-col">-->
        <div class="l-col">
          <TabView :active-index="active">
            <TabPanel
              :header="$t('tab.servers')"
              class="p-tabview-nav-link"
              :style="{ overflow: 'visible' }"
            >
              <component
                :is="'RuleServersCreate'"
                :id="createdRule.id"
                :subnet-id="subnetId"
                :protocol="+createdRule.server.port"
                type="create"
                @add-servers="createMember"
              ></component>
            </TabPanel>
            <TabPanel :header="$t('tab.algorithm')" class="p-tabview-nav-link">
              <component
                :is="'RuleAlgorithm'"
                :id="createdRule.id"
                :protocol="createdRule.balancer.protocol"
                type="create"
                @add-algorithm="createAlgorithm"
                @update-view-cookie-name="isViewCookieNameEmit"
              ></component>
            </TabPanel>
            <TabPanel :header="$t('tab.check')" class="p-tabview-nav-link">
              <component
                :is="'RuleCheck'"
                :id="createdRule.id"
                :protocol="createdRule.balancer.protocol"
                :proxy="createdRule.server.protocol === 'PROXY'"
                type="create"
                @add-check="createCheck"
              ></component>
            </TabPanel>
            <TabPanel :header="$t('tab.connect')" class="p-tabview-nav-link">
              <component
                :is="'RuleConnect'"
                :id="createdRule.id"
                type="create"
                @add-connect="createConnect"
              ></component>
            </TabPanel>
            <TabPanel
              :disabled="!createdRule.isHeaders"
              :header="$t('tab.headers')"
              class="p-tabview-nav-link"
            >
              <component
                :is="'RuleHeaders'"
                v-if="createdRule.isHeaders"
                :id="createdRule.id"
                :rule="rule"
                type="create"
                @add-headers="createHeaders"
              ></component>
            </TabPanel>
          </TabView>
        </div>
      </page-block>
      <div v-if="!balancer" slot="footerEnd" class="rule-block__actions" style="float: right">
        <base-button :disabled="!viewAddButton" class="rule-block__btn" @click="newRule">
          {{ $t('addRule') }}
        </base-button>
      </div>
    </div>
  </main-card>
</template>

<script>
import BaseSelect from '@/components/Select/BaseSelect';
import TabView from 'primevue/tabview';
import BaseLoader from '@/components/BaseLoader/BaseLoader.vue';
import TabPanel from 'primevue/tabpanel';
import RuleServersCreate from '@/layouts/Stack/pages/Main/components/RuleServersCreate';
import RuleAlgorithm from '@/layouts/Stack/pages/Main/components/RuleAlgorithm';
import RuleCheck from '@/layouts/Stack/pages/Main/components/RuleCheck';
import RuleConnect from '@/layouts/Stack/pages/Main/components/RuleConnect';
import RuleHeaders from '@/layouts/Stack/pages/Main/components/RuleHeaders';
import BaseInput from '@/components/BaseInput/BaseInput';
import addContainer from '@/mixins/addContainer';
import PoolView from '@/layouts/Stack/server/PoolView';

export default {
  name: 'AddNewRule',
  components: {
    BaseSelect,
    TabView,
    TabPanel,
    RuleServersCreate,
    RuleAlgorithm,
    RuleCheck,
    BaseLoader,
    RuleConnect,
    RuleHeaders,
    BaseInput,
  },
  mixins: [addContainer],
  props: {
    rule: {
      type: Object,
      required: true,
      default: () => {},
    },
    protocol: {
      type: Array,
      default: () => [],
    },
    id: {
      type: Number,
      required: true,
      // default: ,
    },
    balancer: {
      type: Boolean,
      default: false,
    },
    created: {
      type: Boolean,
      default: false,
    },
    subnetId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      error: '',
      isCurrentLoading: false,
      description: '',
      name: '',
      createdRule: {},
      // customValidationMsgs: {
      //   confirm: { patternMismatch: this.$t('error.created') },
      //   // confirm: { patternMismatch: 'test' },
      // },
      active: 0,
      selected: 'RuleServers',
      isServerError: false,
      isViewCookieName: true,

      isMobile: true,
      protocolRules: ['TCP', 'UDP', 'HTTP', 'HTTPS'],
      tabs: [
        {
          title: this.$t('tab.servers'),
          component: 'RuleServersCreate',
          show: true,
        },
        {
          title: this.$t('tab.algorithm'),
          component: 'RuleAlgorithm',
          show: true,
        },
        {
          title: this.$t('tab.check'),
          component: 'RuleCheck',
          show: true,
        },
        {
          title: this.$t('tab.connect'),
          component: 'RuleConnect',
          show: true,
        },
        {
          title: this.$t('tab.view'),
          component: 'PoolView',
          show: true,
        },
        {
          title: this.$t('tab.headers'),
          component: 'RuleHeaders',
          show: false,
        },
      ],
    };
  },
  computed: {
    // ssl() {
    //   const list = this.$store.state.moduleStack.containers.map(x => x.name);
    //   list.push(this.$t('new SSL'));
    //   return list;
    // },
    ssl() {
      const list = this.$store.state.moduleStack.containers;
      if (!list.map(x => x.id).includes('new')) list.push({ name: this.$t('new SSL'), id: 'new' });
      return list;
    },

    isSslEnabled() {
      return this.createdRule.isSsl && Object.keys(this.createdRule.ssl).length === 0;
    },
    customValidationMsgs() {
      return {
        confirm: {
          patternMismatch: this.validateNum(this.createdRule.balancer.port, 1, 65535)
            ? this.$t('error.created')
            : this.$t('error.new'),
        },
      };
    },
    customValidationServerMsgs() {
      return {
        confirm: {
          patternMismatch: this.$t('error.new'),
        },
      };
    },
    hasBalancerEnabledPort() {
      // console.log(this.protocol);
      // console.log(this.createdRule);
      let gotcha = false;
      if (
        !!this.createdRule &&
        !!this.createdRule.balancer &&
        !!this.createdRule.balancer.port &&
        !!this.protocol
      ) {
        this.protocol.forEach(item => {
          // console.log(item.protocol, this.createdRule.balancer.protocol);
          // console.log(item.protocol, this.createdRule.balancer.protocol);
          // console.log(item.port, this.createdRule.balancer.port);
          if (
            item.protocol === 'UDP' &&
            item.port === +this.createdRule.balancer.port &&
            item.protocol === this.createdRule.balancer.protocol
          ) {
            // console.log('UDP');
            gotcha = true;
          } else if (
            // ['TCP', 'HTTPS', 'HTTP'].includes(this.createdRule.balancer.protocol) &&
            ['TCP', 'HTTPS', 'HTTP'].includes(item.protocol) &&
            this.createdRule.balancer.protocol !== 'UDP' &&
            item.port === +this.createdRule.balancer.port
          ) {
            // console.log('other');
            gotcha = true;
          }
        });
      }
      // console.log(gotcha);
      return gotcha;
      // if (
      //   !!this.createdRule &&
      //   !!this.createdRule.balancer &&
      //   !!this.createdRule.balancer.port &&
      //   !!this.protocol
      // ) {
      //   return this.protocol.includes(+this.createdRule.balancer.port);
      // } else return false;
    },
    viewAddButton() {
      return (
        this.createdRule &&
        this.createdRule.name &&
        this.createdRule.members &&
        this.validateNum(this.createdRule.balancer.port, 1, 65535) &&
        this.validateNum(this.createdRule.server.port, 1, 65535) &&
        this.createdRule.members.length > 0 &&
        this.createdRule.balancer.port &&
        this.createdRule.server.port &&
        !this.hasBalancerEnabledPort &&
        this.isViewCookieName &&
        !this.isSslEnabled
      );
    },
  },
  watch: {
    'createdRule.ssl': function (event) {
      // console.log(event);
      if (event.id === 'new') {
        this.createdRule.ssl = {};
        this.addContainer();
      }
    },
    viewAddButton(event) {
      // console.log('viewAddButton', event);
      this.createdRule.isShowButton = event;
    },
    createdRule: {
      deep: true,
      handler: function (value) {
        // console.log('createdRule', value, this.id, value.id);
        this.$emit('update-rule', value);
        // this.update('options', value);
      },
    },
    hasBalancerEnabledPort: {
      handler: function (value) {
        // console.log(value);
        if (value) {
          this.error = 'Уже есть';
        } else this.error = '';
      },
      immediate: true,
    },
  },
  async mounted() {
    this.createdRule = await Object.assign({}, this.rule);
    // console.log('mounted', this.createdRule, this.id);
    this.loading = false;
  },
  methods: {
    deleteRule(id) {
      // console.log('deleteRule', id);
      return this.$emit('remove-rule', id);
    },
    setView(value) {
      this.tabs.find(el => el.component === 'RuleHeaders').show = value;
    },
    validateNum(input, min, max) {
      let num = +input;
      // console.log(num >= min && num <= max && input === num.toString());
      return num >= min && num <= max && input === num.toString();
    },
    onChangeRule(item, type, event) {
      // console.log('onChangeRule', item, type, event, this.id);
      // console.log(this.createdRule.server.port, this.rule.server.port);
      if (item === this.id) {
        // this.createdRule.isShowButton = this.viewAddButton;
        if (event && event === 'HTTP') {
          this.createdRule = Object.assign(this.createdRule, {
            isSsl: false,
            show: true,
            server: {
              protocol: event,
              port: this.createdRule.server.port || this.rule.server.port,
            },
            // balancer: { port: 80, protocol: event },
            balancer: { port: this.rule.balancer.port, protocol: event },
            protocolServer: ['HTTP'],
            isHeaders: true,
          });
          this.setView(true);
          // console.log(this.createdRule.id, this.id);
        }
        if (event && event === 'HTTPS') {
          this.createdRule = Object.assign(this.createdRule, {
            isSsl: true,
            show: true,
            server: {
              protocol: 'HTTP',
              port: this.createdRule.server.port || this.rule.server.port,
            },
            balancer: { port: 443, protocol: event },
            // balancer: { port: this.rule.balancer.port, protocol: event },
            protocolServer: ['HTTP'],
            isHeaders: true,
          });
          this.setView(true);
          // console.log(this.createdRule.id, this.id);
        }
        if (event && event === 'TCP') {
          // console.log('TCP', this.id);
          this.createdRule = Object.assign(this.createdRule, {
            isSsl: false,
            show: false,
            server: {
              protocol: 'TCP',
              port: this.createdRule.server.port || this.rule.server.port,
            },
            // balancer: { port: 80, protocol: 'TCP' },
            balancer: { port: this.rule.balancer.port, protocol: 'TCP' },
            // balancer: { protocol: 'TCP' },
            protocolServer: ['TCP'],
            isHeaders: false,
          });
          this.setView(false);
          // console.log(this.createdRule.id, this.id);
        }
        if (event && event === 'PROXY') {
          // console.log('TCP', this.id);
          this.createdRule = Object.assign(this.createdRule, {
            isSsl: false,
            show: false,
            server: {
              protocol: 'PROXY',
              port: this.createdRule.server.port || this.rule.server.port,
            },
            // balancer: { port: 80, protocol: 'TCP' },
            balancer: { port: this.rule.balancer.port, protocol: 'TCP' },
            // balancer: { protocol: 'TCP' },
            protocolServer: ['PROXY'],
            isHeaders: false,
          });
          this.setView(false);
          // console.log(this.createdRule.id, this.id);
        }
        if (event && event === 'UDP') {
          this.createdRule = Object.assign(this.createdRule, {
            isSsl: false,
            show: false,
            server: {
              protocol: 'UDP',
              port: this.createdRule.server.port || this.rule.server.port,
            },
            // balancer: { port: 80, protocol: 'UDP' },
            balancer: { port: this.rule.balancer.port, protocol: 'UDP' },
            // balancer: { protocol: 'UDP' },
            protocolServer: ['UDP'],
            isHeaders: false,
          });
          this.setView(false);
          // console.log(this.createdRule.id, this.id);
        }
      }
    },
    sslNames({ name }) {
      return name;
    },
    createMember(payload) {
      if (payload.members) {
        // console.log('createMember', payload);
        this.createdRule.members = payload.members;
      } else {
        this.createdRule.members = [];
      }
    },
    createCheck(payload) {
      // console.log('createHealthMonitor', payload);
      this.createdRule.healthmonitor = Object.assign({}, payload.healthmonitor);
    },
    isViewCookieNameEmit(payload) {
      this.isViewCookieName = payload;
      // console.log(payload);
    },
    createAlgorithm(payload) {
      // console.log('createAlgorithm', payload);
      this.createdRule.pool = Object.assign({}, payload.pool);
    },
    createHeaders(payload) {
      // console.log('createHeaders', payload, payload.headers);
      // this.createdRule.headers = Object.assign({}, payload.headers);
      this.createdRule.insert_headers = Object.assign({}, payload.headers);
      this.createdRule.headers = Object.assign({}, payload.headers);
    },
    createConnect(payload) {
      // console.log('createConnect', payload);
      this.createdRule.listener = Object.assign({}, payload.listener);
    },
    newRule() {
      this.$emit('add-new-rule');
    },
  },
};
</script>
<i18n>
{
  "ru": {
    "addRule": "Добавить правило",
    "title": {
      "certificate": "Форма добавления сертификата"
    },
    "sure": {
      "confirm": "Добавить ключ",
      "certificate": "Добавить сертификат",
      "success": "Сертификат успешно добавлен",
      "close": "Закрыть"
    },
    "port": {
      "server": "Порт сервера",
      "balancer": "Порт балансировщика"
    },
    "protocol": {
      "server": "Протокол сервера",
      "balancer": "Протокол балансировщика"
    },
    "new SSL": "Добавить сертификат",
    "error": {
      "created":"Протокол с таким портом уже существует",
      "new":"Порт должен быть в диапазоне [1,65535]"
    },
    "tableHead": {
      "name": "Правило",
      "type": "Тип",
      "network": "Сеть",
      "shared": "Публичная",
      "config": "Конфигурация",
      "conditions": "",
      "status": "Статус",
      "state": "Состояние",
      "port": "Порты",
      "menu": "Меню"
    },
    "nav": {
      "servers": "Серверы",
      "connect": "Соединения",
      "check": "Проверка",
      "headers": "Заголовки",
      "algorithm": "Алгоритм"
    },
    "rule": {
      "title": "Новое правило",
      "hint": "Подсказка",
      "deschint": "Описание",
      "description": "Описание",
      "name": "Правило"
    },
    "tab": {
      "servers": "Серверы",
      "connect": "Соединения",
      "check": "Проверка",
      "headers": "Заголовки",
      "algorithm": "Алгоритм"
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
$vd-aside-size = 260px;


.rule {
&__form {
+breakpoint(sm-and-up) {
flexy(flex-start, flex-end, wrap);
}
+breakpoint(md-and-up) {
  flex-wrap: nowrap;
  padding-bottom: 1rem;
}
}

&__field {
+breakpoint(sm-and-up) {
  flex: 1 1 auto;
}

& + & {
    margin-top: 0.25rem;

+breakpoint(sm-and-up) {
  margin-top: 0;
  margin-left: 1.5rem;
}
}
}
&__card {
   margin-bottom: 1rem;
 }
}
</style>
